<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row class="mt-8">
        <v-col cols="12">
          <div>
            <v-card class="card-shadow border-radius-xl">
              <div class="card-header-padding">
                <div class="d-flex align-center">
                  <div>
                    <h5 class="font-weight-bold text-h5 text-typo mb-0">
                      Mark
                    </h5>
                    <!-- <p class="text-sm text-body mb-0">
                      Possible states of a document
                    </p> -->
                  </div>
                  <v-spacer></v-spacer>
                  <v-btn
                    elevation="0"
                    dark
                    height="43"
                    @click="dialog_bulk_mark = true"
                    class="
                      font-weight-bold
                      text-capitalize
                      ms-auto
                      btn-primary
                      bg-success
                      py-3
                      px-6
                      ms-0
                      ma-2
                    "
                    >Add multiple Mark</v-btn
                  >
                  <v-dialog v-model="dialog" max-width="750px" persistent>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        elevation="0"
                        dark
                        :ripple="false"
                        height="43"
                        class="
                          font-weight-bold
                          text-capitalize
                          ms-auto
                          btn-primary
                          bg-success
                          py-3
                          px-6
                          ms-3
                        "
                        >New Mark</v-btn
                      >
                    </template>
                    <v-card class="card-shadow border-radius-xl">
                      <div class="card-header-padding card-border-bottom">
                        <span class="font-weight-bold text-h5 text-typo mb-0">{{
                          formTitle
                        }}</span>
                      </div>
                      <v-card-text class="card-padding">
                        <v-container class="px-0">
                          <v-form ref="frm">
                            <v-row>
                              <v-col cols="6">
                                <label
                                  class="
                                    text-md text-typo
                                    font-weight-bolder
                                    ms-1
                                  "
                                  >Mark</label
                                >
                                <v-text-field
                                  v-model="editedItem.mark_name"
                                  @change="getCode"
                                  hide-details="auto"
                                  class="
                                    input-style
                                    font-size-input
                                    text-light-input
                                    placeholder-light
                                    input-icon
                                    mt-2
                                  "
                                  :rules="[
                                    (v) => !!v || 'Mark Name is required',
                                  ]"
                                  dense
                                  flat
                                  filled
                                  solo
                                  height="46"
                                  placeholder="Mark Name"
                                  persistent-hint
                                ></v-text-field>
                              </v-col>
                              <v-col cols="6">
                                <label
                                  class="
                                    text-md text-typo
                                    font-weight-bolder
                                    ms-1
                                  "
                                  >Mark Code</label
                                >
                                <v-text-field
                                  @keyup="capitalize()"
                                  v-model="editedItem.mark_code"
                                  hide-details="auto"
                                  class="
                                    input-style
                                    font-size-input
                                    text-light-input
                                    placeholder-light
                                    input-icon
                                    mt-2
                                  "
                                  :rules="[
                                    (v) => !!v || 'Mark Code is required',
                                  ]"
                                  dense
                                  flat
                                  filled
                                  solo
                                  height="46"
                                  placeholder="mark Code "
                                  persistent-hint
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="6">
                                <label
                                  class="
                                    text-md text-typo
                                    font-weight-bolder
                                    ms-1
                                  "
                                >
                                  Quality</label
                                >
                                <v-text-field
                                  v-model="editedItem.mark_quality"
                                  hide-details="auto"
                                  class="
                                    input-style
                                    font-size-input
                                    text-light-input
                                    placeholder-light
                                    input-icon
                                    mt-2
                                  "
                                  :rules="[(v) => !!v || 'Quality is required']"
                                  dense
                                  flat
                                  filled
                                  solo
                                  height="46"
                                  placeholder="Quality"
                                  persistent-hint
                                ></v-text-field>
                              </v-col>
                              <v-col cols="6">
                                <label
                                  class="
                                    text-md text-typo
                                    font-weight-bolder
                                    ms-1
                                  "
                                >
                                  Mark Type</label
                                >
                                <v-select
                                  v-model="editedItem.mark_type"
                                  :items="markType"
                                  item-text="name"
                                  item-value="name"
                                  color="rgba(0,0,0,.6)"
                                  class="
                                    input-style
                                    font-size-input
                                    text-light-input
                                    placeholder-light
                                    border-radius-md
                                    select-style
                                    mt-2
                                    mb-0
                                  "
                                  :rules="[
                                    (v) => !!v || 'Mark Type is Required',
                                  ]"
                                  outlined
                                  single-line
                                  height="46"
                                >
                                </v-select>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="6">
                                <label
                                  class="
                                    text-md text-typo
                                    font-weight-bolder
                                    ms-1
                                  "
                                >
                                  District</label
                                >
                                <v-autocomplete
                                  v-model="editedItem.district"
                                  :items="districts"
                                  return-object
                                  item-text="district_name"
                                  item-value="id"
                                  class="
                                    input-style
                                    font-size-input
                                    text-light-input
                                    placeholder-light
                                    border-radius-md
                                    select-style
                                    mt-2
                                    mb-0
                                  "
                                  outlined
                                  single-line
                                  height="46"
                                >
                                  <template v-slot:no-data>
                                    <v-list-item>
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          <v-btn @click="createDist"
                                            >Create</v-btn
                                          >
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="6">
                                <label
                                  class="
                                    text-md text-typo
                                    font-weight-bolder
                                    ms-1
                                  "
                                >
                                  Certificate</label
                                >
                                <v-select
                                  v-model="editedItem.certificate"
                                  :items="certificates"
                                  return-object
                                  item-text="certificate_name"
                                  item-value="id"
                                  color="rgba(0,0,0,.6)"
                                  class="
                                    input-style
                                    font-size-input
                                    text-light-input
                                    placeholder-light
                                    border-radius-md
                                    select-style
                                    mt-2
                                    mb-0
                                  "
                                  outlined
                                  single-line
                                  height="46"
                                >
                                </v-select>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="">
                                <label
                                  class="
                                    text-md text-typo
                                    font-weight-bolder
                                    ms-1
                                  "
                                >
                                  Auction center</label
                                >
                                <v-select
                                  v-model="editedItem.auction_center"
                                  :items="auctionCenters"
                                  return-object
                                  item-text="auction_center_name"
                                  item-value="id"
                                  class="
                                    input-style
                                    font-size-input
                                    text-light-input
                                    placeholder-light
                                    select-style
                                    mt-3
                                  "
                                  outlined
                                  chips
                                  multiple
                                  single-line
                                  height="46"
                                >
                                  <template v-slot:selection="{ item, index }">
                                    <v-chip
                                      label
                                      color="bg-default"
                                      class="py-1 px-2 my-0"
                                      v-if="index === 0"
                                    >
                                      <span
                                        class="text-white text-caption ls-0"
                                        >{{ item.auction_center_name }}</span
                                      >
                                    </v-chip>
                                    <span
                                      v-if="index === 1"
                                      class="grey--text text-caption"
                                    >
                                      (+{{
                                        editedItem.auction_center.length - 1
                                      }}
                                      others)
                                    </span>
                                  </template>
                                </v-select>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-container>
                      </v-card-text>

                      <v-card-actions class="card-padding d-flex justify-end">
                        <v-btn
                          @click="close"
                          elevation="0"
                          :ripple="false"
                          height="43"
                          class="
                            font-weight-bold
                            text-capitalize
                            btn-ls btn-secondary
                            bg-light
                            py-3
                            px-6
                          "
                          >Cancel</v-btn
                        >

                        <v-btn
                          @click="save"
                          elevation="0"
                          :ripple="false"
                          height="43"
                          dark
                          class="
                            text-capitalize
                            btn-ls btn-primary
                            bg-success
                            py-3
                            px-6
                          "
                          >Save</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </div>

              <v-card-text class="px-0 py-0">
                <v-data-table
                  :headers="headers"
                  :items="items"
                  :search="search"
                  class="table"
                  :page.sync="page"
                  hide-default-footer
                  @page-count="pageCount = $event"
                  :items-per-page="itemsPerPage"
                  mobile-breakpoint="0"
                >
                  <template v-slot:top>
                    <v-toolbar flat height="80">
                      <v-row>
                        <v-col cols="12" md="5">
                          <v-text-field
                            hide-details
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                            dense
                            flat
                            filled
                            solo
                            height="43"
                            v-model="search"
                            placeholder="Search"
                          >
                            <template slot="prepend-inner">
                              <v-icon color="#adb5bd" size=".875rem"
                                >fas fa-search</v-icon
                              >
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>

                      <v-spacer></v-spacer>

                      <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card
                          class="card-shadow card-padding border-radius-xl"
                        >
                          <v-card-title
                            class="pt-0 text-h5 text-typo justify-center"
                            >Are you sure you want to delete this
                            item?</v-card-title
                          >
                          <v-card-actions class="pb-0">
                            <v-spacer></v-spacer>
                            <v-btn
                              @click="closeDelete"
                              elevation="0"
                              :ripple="false"
                              height="43"
                              class="
                                font-weight-bold
                                text-capitalize
                                btn-ls
                                bg-light
                                py-3
                                px-6
                              "
                              >Cancel</v-btn
                            >

                            <v-btn
                              @click="deleteItemConfirm"
                              elevation="0"
                              :ripple="false"
                              height="43"
                              class="
                                font-weight-bold
                                text-capitalize
                                btn-ls btn-primary
                                bg-success
                                py-3
                                px-6
                              "
                              >Ok</v-btn
                            >
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </template>

                  <template v-slot:[`item.center`]="{ item }">
                    <v-chip
                      class="ma-2"
                      v-for="centers in item.auction_center"
                      :key="centers.id"
                    >
                      {{ centers.auction_center_name }}
                    </v-chip>
                  </template>
                  <!-- [`item.actions`]="{ item }" -->
                  <template v-slot:[`item.actions`]="{ item }">
                    <!-- <span> Example test {{ item.district }}</span> -->
                    <v-btn
                      @click="editItem(item)"
                      icon
                      elevation="0"
                      :ripple="false"
                      height="28"
                      min-width="36"
                      width="36"
                      class=""
                      color="green"
                      >Edit
                    </v-btn>

                    <v-btn
                      @click="deleteItem(item)"
                      icon
                      elevation="0"
                      :ripple="false"
                      height="28"
                      min-width="36"
                      width="36"
                      class="ml-5"
                      color="#FF0000"
                    >
                      delete
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card-text>
              <v-card-actions class="card-padding">
                <v-row>
                  <v-col cols="6" lg="4" class="d-flex align-center">
                    <span class="text-body me-3 text-sm">Items per page:</span>
                    <v-select
                      hide-details
                      :items="paginationLimits"
                      item-text="label"
                      item-value="id"
                      outlined
                      background-color="rgba(255,255,255,.9)"
                      color="rgba(0,0,0,.6)"
                      light
                      v-model="itemsPerPage"
                      placeholder="Items per page"
                      class="
                        font-size-input
                        placeholder-lighter
                        text-color-light
                        input-alternative input-focused-alternative input-icon
                      "
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="6" class="ml-auto d-flex justify-end">
                    <v-pagination
                      prev-icon="fa fa-angle-left"
                      next-icon="fa fa-angle-right"
                      class="pagination"
                      color="#38bd34"
                      v-model="page"
                      :length="pageCount"
                      circle
                    ></v-pagination>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <MarkBulk
      :dialog_bulk_mark="dialog_bulk_mark"
      @close_dialog_bulk_mark="closeDialogBulkMark()"
    />
  </div>
</template>
<script>
import api from "../api";
import MarkBulk from "./MarkBulk.vue";
import { paginationLimits } from "../../../../global-data/paginationLimits";

export default {
  name: "paginated-tables",
  components: {
    MarkBulk,
  },
  data() {
    return {
      dialog_bulk_mark: false,
      page: 1,
      pageCount: 0,
      paginationLimits: paginationLimits,
      itemsPerPage: 10,

      dialog: false,
      dialogDelete: false,
      items: [],
      search: "",

      districts: [],
      certificates: [],
      auctionCenters: [],
      markType: [
        {
          name: "Bought Leaf",
        },
        {
          name: "Estate",
        },
      ],
      editedIndex: -1,
      editedItem: {
        mark_name: "",
        mark_code: "",
        mark_quality: 0,
        district: null,
        certificate: null,
        auction_center: [],
        markType: null,
        id: 0,
      },
      defaultItem: {
        mark_name: "",
        mark_code: "",
        mark_quality: 0,
        district: null,
        certificate: null,
        auction_center: [],
        markType: null,
        id: 0,
      },
      headers: [
        {
          text: "Mark Name",
          width: "150",
          sortable: true,
          value: "mark_name",
        },
        {
          text: "Mark Code",
          width: "150",
          sortable: true,
          value: "mark_code",
        },
        {
          text: "Mark Quality",
          width: "250",
          sortable: true,
          value: "mark_quality",
        },
        {
          text: "Mark Type",
          width: "150",
          sortable: true,
          value: "mark_type",
        },
        {
          text: "Auction Center",

          value: "center",
        },
        { text: "Actions", width: "150", value: "actions", sortable: false },
      ],
    };
  },

  created() {
    this.initialize();
  },
  methods: {
    validatefrm() {
      return this.$refs.frm.validate();
    },
    reset() {
      return this.$refs.frm.reset();
    },

    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 1000,
        icon: "success",
        showConfirmButton: false,
      });
    },

    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        type: "error",

        icon: "error",
        showConfirmButton: true,
      });
    },

    async initialize() {
      this.items = await api.get();
      this.districts = await api.getDistrict();
      this.certificates = await api.getCertificate();
      this.auctionCenters = await api.getAuctionCenter();
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    async deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.closeDelete();
      try {
        await api.delete(this.editedItem.id);
        this.items.splice(this.editedIndex, 1);

        this.showSuccessAlert(`Mark Deleted.`);
      } catch (error) {
        this.showErrorAlert(error.message);
      }
    },
    closeDialogBulkMark() {
      this.dialog_bulk_mark = false;
      this.initialize();
    },

    close() {
      this.dialog = false;
      this.reset();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      // this.reset();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    createDist() {
      console.log("Create", this.editedItem.district);
    },
    capitalize() {
      this.editedItem.mark_code = this.editedItem.mark_code
        .toUpperCase()
        .substring(0, 4);

      // this.editedItem.mark_code = this.editedItem.mark_code.toUpperCase();
    },
    async save() {
      if (this.validatefrm()) {
        try {
          let type = "Saved";
          if (this.editedIndex > -1) {
            type = "Updated";
            await api.edit(this.editedItem.id, this.editedItem);
            Object.assign(this.items[this.editedIndex], this.editedItem);
          } else {
            let item = await api.create(this.editedItem);
            this.items.push(item);
          }
          this.showSuccessAlert(`Mark ${type}.`);
          this.close();
        } catch (err) {
          this.showErrorAlert(err.message);
        }
      }
    },
    getCode() {
      this.editedItem.mark_code = this.editedItem.mark_name
        .toUpperCase()
        .substring(0, 4);
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Mark   " : "Edit Mark";
    },
    pages() {
      return this.pagination.rowsPerPage
        ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
        : 0;
    },
  },
};
</script>
